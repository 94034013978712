<script lang="ts" setup>
  import { useMediaQuery, useScrollLock } from '@vueuse/core';
  import { useToggle } from '@vueuse/shared';
  import { useRouter } from 'vue-router';
  import VButton from '~/components/ui/VButton.vue';

  const VModalMobile = defineAsyncComponent(() => import('~/components/modals/VModalMobile.vue'));

  const { beforeEach } = useRouter();
  const modalLock = useScrollLock(document.body);
  const toggleLock = useToggle(modalLock);

  interface IMobileModal {
    enabled: boolean;
    title: string | null,
    onBackBtn: () => void | null;
  }

  interface IProps {
    size?: 'full' | 'extra' | 'large' | 'middle' | 'small' | 'less';
    loader?: boolean;
    hideCloseButton?: boolean;
    disableTransition?: boolean;
    transparent?: boolean;
    mobileModal?: IMobileModal;
  }
  const props = withDefaults(defineProps<IProps>(), {
    size: 'middle',
    mobileModal: {
      enabled: false,
    },
  });
  const emits = defineEmits<{
    unmounted: [];
    mounted: [];
    close: [];
  }>();

  const modalVisible = defineModel<boolean>();
  toggleLock();

  const isRetina = useMediaQuery('(max-width: 640px)');
  const isMobileModalVisible = computed(() => props.mobileModal.enabled && isRetina.value);

  const closeModal = () => {
    modalVisible.value = false;
    emits('close');
  };

  const onMobileBackBtnClick = () => {
    props.mobileModal.onBackBtn ? props.mobileModal.onBackBtn() : closeModal();
  };

  emits('mounted');
  onBeforeUnmount(() => {
    emits('unmounted');
    toggleLock();
  });
  beforeEach(() => {
    if (modalLock.value) closeModal();
  });
</script>

<template>
  <div class="modal">
    <transition name="fade" appear>
      <div class="modal__overlay"></div>
    </transition>
    <transition :name="props.disableTransition ? '' : 'pop'" appear>
      <v-modal-mobile
        v-if="isMobileModalVisible"
        v-bind="mobileModal"
        @back-btn-handler="onMobileBackBtnClick"
      >
        <slot />
      </v-modal-mobile>
      <component v-else :is="'div'" class="modal__wrapper" :class="{ transparent }" :data-size="props.size">
        <v-button
          v-if="!props.hideCloseButton"
          class="modal__close"
          appearance="text"
          color="white"
          icon="close"
          @click="closeModal"
        />
        <div v-if="$slots.header" class="modal__header">
          <slot name="header" />
        </div>
        <div v-if="$slots.default" class="modal__content">
          <slot />
        </div>
        <div v-if="$slots.footer" class="modal__footer">
          <slot name="footer" />
        </div>
        <div v-if="props.loader" class="modal__preloader">
          <v-preloader />
        </div>
      </component>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    $parent: &;

    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;

    &__overlay {
      position: fixed;
      inset: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(35px);
      will-change: transform;
      transform: translate3d(0, 0, 0);
    }
    &__wrapper {
      position: relative;
      width: 500px;
      min-height: 200px;
      max-height: 100%;
      border-radius: 24px;
      padding: 40px 64px;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%), #0b0b0b;
      z-index: 1;
      overflow: hidden;
      will-change: transform;
      transform: translate3d(0, 0, 0);
      @include scrollbars(14px, 4px, $main_yellow_light, $main_grey_extra_dark);

      &:after {
        content: '';
        inset: 0;
        position: absolute;
        background: linear-gradient(238deg, rgb(53 19 54 / 35%) 8.3%, rgb(42 44 50 / 0%) 89.66%);
        z-index: -1;
      }
      &[data-size='less'] {
        width: 100vw;
        height: 100%;
        border-radius: 0;
        padding: 0;
      }
      &[data-size='full'] {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        background: transparent;
        overflow: auto;
        &:after {
          background: transparent;
        }
        @media (max-width: $retina) {
          height: 100vh;
          height: calc(var(--app-height));
        }
      }
      &[data-size='extra'] {
        width: 1100px;
        padding: 40px;
      }
      &[data-size='large'] {
        width: 700px;
        padding: 40px;
      }
      &[data-size='small'] {
        padding: 40px;
      }
      &.transparent {
        background: transparent;
      }
    }
    &__close {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 0;
      width: fit-content;
    }
    &__preloader {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(7px);
    }
    @media (max-width: $retina) {

      .modal__wrapper {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 24px 16px;
        overflow-y: auto;

        @include scrollbars(14px, 4px, transparent, transparent);
        
        &[data-size='full'] {
          padding: 48px 16px 24px 16px;
          #{$parent}__close {
            top: 55px;
            right: 10px;
          }
        }
        &[data-size='large'] {
          padding: 45px 16px;
        }
        &[data-size='small'] {
          padding: 45px 16px;
        }
      }
    }
  }
</style>
